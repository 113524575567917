<template>
  <v-container fluid fill-height>
    <v-layout align-center justify-center>
      <v-flex xs12 sm12>
        <v-row class="mb-5 mt-10" align="center" justify="space-around">
        </v-row>
        <v-row class="mb-16" align="center" justify="space-around">
          <div>{{ greeting }}</div>
        </v-row>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
export default {
  name: "AppHome",
  components: {},
  data() {
    return {
      greeting: "Selamat datang"
    };
  }
};
</script>
